<template>
<div class="vue-tree-list pa-0">
  <tree
    v-if="getTreeData.length"
    v-model="getValue"
    ref="tree"
    :data="getTreeData"
    :options="treeOptions"
  />
  <!--<tree-item-->
  <!--v-for="item in getTreeData"-->
  <!--v-model="getValue"-->
  <!--:key="item.uuid"-->
  <!--:item="item"-->
  <!--:isOwn="!!item.children.length"-->
  <!--:valueField="valueField"-->
  <!--:textField="textField"-->
  <!--/>-->
</div>
</template>

<script>

export default {
  name: 'VueTree',
  props: {
    treeData: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    valueField: {
      type: String,
      default: 'uuid',
    },
    textField: {
      type: String,
      default: 'name',
    },
    childField: {
      type: String,
      default: 'children',
    },
  },
  data() {
    return {
      treeOptions: {
        checkbox: true,
        propertyNames: {
          text: this.textField,
          id: this.valueField,
          children: this.childField,
        },
      },
    }
  },
  computed: {
    getValue: {
      get() {
        return null
      },
      set(v) {
        this.$emit('input', v.checked.map((m) => m.id))
      },
    },
    getTreeData() {
      return this.onRecursion(this.treeData)
    },
  },
  methods: {
    onRecursion(items) {
      return items.map((item) => {
        if (item.children.length) {
          if (this.value.includes(item.uuid)) {
            item.state = {
              checked: true,
              expanded: true, 
            } 
          }
          this.onRecursion(item.children)
        } else if (this.value.includes(item.uuid)) item.state = { checked: true }
        return item
      })
    },
  },
}
</script>
<style lang="scss">
  .vue-tree-list{
    .tree-checkbox{
      width: 18px;
      height: 18px;
    }
    .tree-checkbox.checked:after {
      left: 5px;
      top: 2px;
      height: 8px;
      width: 4px;
    }
    .tree-checkbox.indeterminate:after {
      height: 1px;
    }
    .tree-checkbox.checked, .tree-checkbox.indeterminate {
      background-color: #43436e;
      border-color: #1e1f2d;
    }
  }
</style>
